import React from "react";

export const useLinksWidget = () => {
  React.useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://web-cdn.fixr.co/scripts/fixr-checkout-widget.v1.min.js?theme=dark&salesChannel=whitelabel-website";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};
