import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import { PageHeader } from "components/PageHeader";
import { PageTitle } from "components/PageTitle";
import { useInView } from "hooks/useInView";
import { useLinksWidget } from "hooks/useLinksWidget";
import React, { useRef } from "react";

export default function JoinTheTeamPage() {
  const photosRef = useRef(null);
  const photosInView = useInView(photosRef, {
    threshold: 0,
  });

  useLinksWidget();

  return (
    <Page>
      <PageHeader imageSrc="/join-the-team.jpg" />

      <section className="container mx-auto text-center py-6 pb-64 mt-32">
        <div className="flex flex-col items-center md:flex-row" ref={photosRef}>
          <MotionImage
            initial={{ scale: 1.1, opacity: 0, y: 40, rotate: -3 }}
            animate={
              photosInView
                ? {
                    opacity: 1,
                    y: 0,
                    rotate: -3,
                    scale: 1.1,
                    transition: { delay: 0.5, duration: 0.8 },
                  }
                : { scale: 1.1, opacity: 0, y: 40, rotate: -3 }
            }
            className="w-10/12 md:w-4/12 shadow-lg	"
            src="join-1.jpg"
          />
          <MotionImage
            initial={{ scale: 1.1, opacity: 0, y: 40, rotate: 12 }}
            animate={
              photosInView
                ? {
                    opacity: 1,
                    y: 0,
                    scale: 1.1,
                    rotate: 12,
                    transition: { delay: 0.6, duration: 0.8 },
                  }
                : { scale: 1.1, opacity: 0, y: 40, rotate: 12 }
            }
            className="w-10/12 md:w-4/12 shadow-lg	"
            src="join-2.jpg"
          />
          <MotionImage
            initial={{ scale: 1.1, opacity: 0, y: 40, rotate: -12 }}
            animate={
              photosInView
                ? {
                    opacity: 1,
                    y: 0,
                    scale: 1.1,
                    rotate: -12,
                    transition: { delay: 0.7, duration: 0.8 },
                  }
                : { scale: 1.1, opacity: 0, y: 40, rotate: -12 }
            }
            className="w-10/12 md:w-4/12 shadow-lg	"
            src="join-3.jpg"
          />
        </div>
      </section>
      <section className="container lg:max-w-4xl mx-auto mt-44">
        <a href="https://fixr.co/event/402521543">SIGNUP NOW</a> TO BE A PART OF
        ONE OF THE BIGGEST PROMOTIONAL TEAMS IN THE MIDLANDS! Hosted Events
        involving; DRAKE, TORY LANEZ,POP SMOKE, STEFFLON DON, HEADIE ONE, NAFE
        SMALLZ, B YOUNG, MOSTACK, NSG, NELLY, KID INK, ASHANTI,YXNG BANE, KOJO
        FUNDS, BIG TOBZ, BELLY SQUAD, HARDY CAPRIO, DIG DAT, OSH, D BLOCK
        EUROPE, AMBUSH, JB SCOFIELD, EO, KOOMZ, ZIEZIE, TIM WESTOOD, KENNY
        ALLSTAR, M HUNCHO &amp; MANY MORE
      </section>
    </Page>
  );
}
